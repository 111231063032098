import React, {useState, useEffect} from 'react';
import {Alert, Spinner} from 'react-bootstrap';
import {fetchStocksAPI} from '../api/Stocks';
import StockTable from '../components/StockTable';
import './Stocks.css';

export default function Stocks (props) {
  const [stocks, setStocks] = useState ([]);
  const [sort, setSort] = useState ([]);
  const [isLoading, setIsLoading] = useState (true);
  const [error, setError] = useState ('');

  const fetchStocks = async () => {
    await fetchStocksAPI ()
      .then (stocks => {
        setStocks (stocks);
        setIsLoading (false);
      })
      .catch (error => {
        if (error.type === 'API' && error.content.code === 401) {
          props.handleLogout ();
        } else {
          setError (error.content.message);
          setIsLoading (false);
        }
      });
  };

  useEffect (() => {
    fetchStocks ();
  }, []);

  const getStockArray = (stocks, searchString) => {
    if (searchString) {
      return stocks.filter (stock => {
        return stock.name.toLowerCase ().match (searchString.toLowerCase ());
      });
    } else {
      return stocks;
    }
  };

  const sortStocks = column => {
    const temp = [...stocks];
    if (column === 'name') {
      if (sort[0] === column && sort[1] === 'ASC') {
        setSort ([column, 'DESC']);
        setStocks (temp.sort ((a, b) => b.name.localeCompare (a.name)));
      } else {
        setSort ([column, 'ASC']);
        setStocks (temp.sort ((a, b) => a.name.localeCompare (b.name)));
      }
    } else {
      if (sort[0] === column && sort[1] === 'ASC') {
        setSort ([column, 'DESC']);
        setStocks (
          temp.sort ((a, b) => b.calculation[column] - a.calculation[column])
        );
      } else {
        setSort ([column, 'ASC']);
        setStocks (
          temp.sort ((a, b) => a.calculation[column] - b.calculation[column])
        );
      }
    }
  };

  return (
    <div className="Stocks">
      {isLoading
        ? <div className="text-center padding">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        : error
            ? <div className="padding">
                <Alert className="text-center" variant="danger">
                  {error}
                </Alert>
              </div>
            : <StockTable
                stocks={getStockArray (stocks, props.search)}
                columns={props.columns}
                sortStocks={sortStocks}
              />}
    </div>
  );
}
