import API from './Config';
import {errorAPI} from '../utils/ErrorHandling';

export const fetchHistoriesAPI = stockID => {
  return new Promise ((resolve, reject) => {
    API.get (`stocks/${stockID}/histories`)
      .then (res => {
        var histories = res.data;
        resolve (histories);
      })
      .catch (error => {
        reject (errorAPI (error));
      });
  });
};
