import API from './Config';
import { errorAPI } from '../utils/ErrorHandling';

export const createAdjustmentAPI = (
  CSRF,
  stockID,
  type,
  year,
  adjustmentValue,
  comment,
  fieldID
) => {
  return new Promise((resolve, reject) => {
    API.post(
      `stocks/${stockID}/${type}/${year}/adjustments`,
      {
        csrf: CSRF,
        value: adjustmentValue,
        comment: comment,
        field_id: fieldID,
      }
    )
      .then(() => {
        resolve(true);
      })
      .catch(error => {
        reject(errorAPI(error));
      });
  });
};

export const updateAdjustmentAPI = (
  CSRF,
  stockID,
  type,
  year,
  adjustmentValue,
  comment,
  fieldID
) => {
  return new Promise((resolve, reject) => {
    API.put(
      `stocks/${stockID}/${type}/${year}/adjustments/${fieldID}`,
      {
        csrf: CSRF,
        value: adjustmentValue,
        comment: comment,
      }
    )
      .then(() => {
        resolve(true);
      })
      .catch(error => {
        reject(errorAPI(error));
      });
  });
};

export const deleteAdjustmentAPI = (CSRF, stockID, type, year, fieldID) => {
  return new Promise((resolve, reject) => {
    API.delete(`stocks/${stockID}/${type}/${year}/adjustments/${fieldID}`, {
      data: {
        csrf: CSRF
      }
    })
      .then(() => {
        resolve(true);
      })
      .catch(error => {
        reject(errorAPI(error));
      });
  });
};
