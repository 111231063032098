import React, {useState, useEffect} from 'react';
import {Card, Button} from 'react-bootstrap';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
} from 'recharts';
import Moment from 'moment';

export default function ChartCard (props) {
  const [dataArray, setDataArray] = useState ([]);

  const renderGraph = () => {
    let array = props.values.reverse ().map (value => {
      var dateFormed;
      if (props.timeframe === 'last7days' || props.timeframe === 'last30days' ) {
        dateFormed = Moment (value.date).format ('DD.MM');
      } else {
        dateFormed = Moment (value.date).format ('DD.MM.YYYY');
      }
      return {
        date: dateFormed,
        value: parseFloat (value.value),
      };
    });
    setDataArray (array);
  };

  const changeTimeframe = (e, timeframe) => {
    e.preventDefault ();
    props.setTimeframe (timeframe);
  };

  useEffect (
    () => {
      renderGraph ();
    },
    [props.values]
  );

  const CustomTooltip = ({active, payload, label}) => {
    if (active) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${label} : ${payload[0].value}`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <div className='paddingOnIpad'>
      <Card>
        <Card.Header>Market valuation</Card.Header>
        <Card.Body style={{margin: "0 auto"}} >
          <LineChart
            width={720}
            height={400}
            className='text-center'
            margin={{top: 20, right: 30, left: 0, bottom: 10}}
            data={dataArray}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis allowDecimals={'false'} type="number" domain={['auto', 'auto']} /> />
            <Tooltip content={<CustomTooltip />} />
            {props.modalKey === 'pe_ratio' &&
              <ReferenceLine
                y={props.stock.calculation.average_pe_ratio}
                stroke="#3D9970"
              />}
            <Line
              dot={false}
              type="linear"
              dataKey="value"
              animationDuration={0}
              name="Market valuation"
            />
          </LineChart>
        </Card.Body>
        <Card.Footer>
          <Button
            variant="secondary"
            onClick={e => changeTimeframe (e, 'last7days')}
          >
            7 days
          </Button> {' '}
          <Button
            variant="secondary"
            onClick={e => changeTimeframe (e, 'last30days')}
          >
            30 days
          </Button>{' '}
          <Button
            variant="secondary"
            onClick={e => changeTimeframe (e, 'last6months')}
          >
            6 months
          </Button> {' '}
          <Button
            variant="secondary"
            onClick={e => changeTimeframe (e, 'last12months')}
          >
            12 months
          </Button>{' '}
          <Button
            variant="secondary"
            onClick={e => changeTimeframe (e, 'last2years')}
          >
            2 years
          </Button>{' '}
          <Button
            variant="secondary"
            onClick={e => changeTimeframe (e, 'last5years')}
          >
            5 years
          </Button>{' '}
          <Button
            variant="secondary"
            onClick={e => changeTimeframe (e, 'last10years')}
          >
            10 years
          </Button>{' '}
          <Button variant="secondary" onClick={e => changeTimeframe (e, 'max')}>
            Max
          </Button>
        </Card.Footer>
      </Card>
    </div>
  );
}
