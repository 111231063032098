import React, {useState, useEffect} from 'react';
import {
  createAdjustmentAPI,
  updateAdjustmentAPI,
  deleteAdjustmentAPI,
} from '../api/Adjustments';
import {Modal, Button, InputGroup, FormControl, Alert} from 'react-bootstrap';
import './AdjustmentModal.css';

export default function AdjustmentModal (props) {
  const [error, setError] = useState ();
  const [field, setField] = useState ();
  const [state, setState] = useState ();
  const [onDelete, setOnDelete] = useState (false);
  const [adjustmentValue, setAdjustmentValue] = useState ();
  const [comment, setComment] = useState ();

  useEffect (
    () => {
      if (props.entry) prepareAdjustment ();
    },
    [props]
  );

  const prepareAdjustment = () => {
    setState ('new');
    setOnDelete (false);
    setError ('');
    switch (parseInt (props.fieldID)) {
      case 1:
        setField ('EPS');
        break;
      case 2:
        setField ('Dividend');
        break;
      case 3:
        setField ('P/E ratio');
        break;
      case 4:
        setField ('Profit growth');
        break;
    }
    var adjustments = [];
    setAdjustmentValue ('');
    setComment ('');
    if (props.entry.adjusted_histories) {
      adjustments = props.entry.adjusted_histories;
    }
    if (props.entry.adjusted_estimates) {
      adjustments = props.entry.adjusted_estimates;
    }
    adjustments.forEach (adjustment => {
      if (adjustment.field.id === props.fieldID) {
        setAdjustmentValue (adjustment.value_real);
        setComment (adjustment.comment);
        setState ('update');
      }
    });
  };

  const getRealValue = () => {
    switch (parseInt (props.fieldID)) {
      case 1:
        return props.entry.eps_real;
      case 2:
        return props.entry.dividend_real;
      case 3:
        return props.entry.pe_ratio;
      case 4:
        return props.entry.profit_growth;
    }
  };

  const getType = () => {
    if (props.entry.adjusted_histories) {
      return 'histories';
    }
    if (props.entry.adjusted_estimates) {
      return 'estimates';
    }
  };

  const refreshData = () => {
    if (getType () === 'histories') props.fetchHistories ();
    else props.fetchEstimates ();
  };

  const handleSubmit = event => {
    event.preventDefault ();
    if (onDelete) {
      deleteAdjustmentAPI (
        props.CSRF,
        props.entry.stock.id,
        getType (),
        props.entry.year,
        props.fieldID
      )
        .then (() => {
          refreshData ();
          props.handleClose ();
        })
        .catch (error => {
          if (error.type === 'API' && error.content.code === 401) {
            props.handleLogout ();
          } else {
            setError (error.content.message);
          }
        });
    } else {
      if (state === 'new') {
        createAdjustmentAPI (
          props.CSRF,
          props.entry.stock.id,
          getType (),
          props.entry.year,
          adjustmentValue,
          comment,
          props.fieldID
        )
          .then (() => {
            refreshData ();
            props.handleClose ();
          })
          .catch (error => {
            if (error.type === 'API' && error.content.code === 401) {
              props.handleLogout ();
            } else {
              setError (error.content.message);
            }
          });
      }
      if (state === 'update') {
        updateAdjustmentAPI (
          props.CSRF,
          props.entry.stock.id,
          getType (),
          props.entry.year,
          adjustmentValue,
          comment,
          props.fieldID
        )
          .then (() => {
            refreshData ();
            props.handleClose ();
          })
          .catch (error => {
            if (error.type === 'API' && error.content.code === 401) {
              props.handleLogout ();
            } else {
              setError (error.content.message);
            }
          });
      }
    }
  };

  return (
    <div>
      {state &&
        <Modal show={props.show} onHide={props.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{field} adjustment for {props.entry.year}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <InputGroup size="sm" className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text id="inputGroup-sizing-sm">
                  Real value
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
                value={getRealValue ()}
                disabled
              />
              {props.fieldID !== 3 &&
                <InputGroup.Append>
                  <InputGroup.Text>
                    {props.fieldID !== 4
                      ? props.entry.stock.currency.currency_symbol
                      : '%'}
                  </InputGroup.Text>
                </InputGroup.Append>}
            </InputGroup>
            {(props.fieldID === 1 || props.fieldID === 2) &&
              <InputGroup size="sm" className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroup-sizing-sm">
                    Adjusted value*
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  aria-label="Small"
                  aria-describedby="inputGroup-sizing-sm"
                  value={adjustmentValue}
                  onChange={e => setAdjustmentValue (e.target.value)}
                />
                <InputGroup.Append>
                  <InputGroup.Text>
                    {props.entry.stock.currency.currency_symbol}
                  </InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>}
            <InputGroup size="sm">
              <InputGroup.Prepend>
                <InputGroup.Text>Comments</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                as="textarea"
                aria-label="With textarea"
                rows="4"
                value={comment}
                onChange={e => setComment (e.target.value)}
              />
            </InputGroup>
            {error &&
              <Alert className="adjustment-modal-alert" variant="danger">
                {error}
              </Alert>}
          </Modal.Body>
          {state === 'new'
            ? <Modal.Footer>
                <Button variant="primary" onClick={handleSubmit}>
                  Add adjustment
                </Button>
                <Button variant="secondary" onClick={props.handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            : <Modal.Footer>
                {onDelete === true
                  ? <Button onClick={handleSubmit} variant="primary">
                      Are you sure?
                    </Button>
                  : <Button variant="danger" onClick={() => setOnDelete (true)}>
                      Remove adjustment
                    </Button>}
                {onDelete === true
                  ? <Button
                      onClick={() => setOnDelete (false)}
                      variant="primary"
                    >
                      Cancel
                    </Button>
                  : <Button variant="primary" onClick={handleSubmit}>
                      Update adjustment
                    </Button>}
                <Button variant="secondary" onClick={props.handleClose}>
                  Close
                </Button>
              </Modal.Footer>}
        </Modal>}
    </div>
  );
}
