import React from 'react';
import {LinkContainer} from 'react-router-bootstrap';
import {FiEdit} from 'react-icons/fi';

export default function StockTableEntry (props) {
  const getColorOverallRanking = amount => {
    let color = '#FF851B';
    {
      color = parseFloat (amount) > 4 ? '#3D9970' : color;
    }
    {
      color = parseFloat (amount) < -15 ? '#FF4136' : color;
    }
    return color;
  };

  const stock = props.columns.map (function (column) {
    if (column.visible) {
      let stock = props.stock;
      if (column.path.split ('.').length === 1) {
        stock = props.stock;
      }
      if (column.path.split ('.').length === 2) {
        stock = props.stock.calculation;
      }
      if (column.path.split ('.').length === 3) {
        stock = props.stock.stock.calculation;
      }
      if (column.name === 'overall_ranking') {
        return (
          <td
            key={column.id}
            style={{
              color: getColorOverallRanking (stock.overall_ranking),
              width: props.width,
            }}
          >
            {stock[column.name] + column.sign}
          </td>
        );
      } else {
        if (column.name === 'amount') {
          return (
            <td
              key={column.id}
              style={{width: props.width}}
              onClick={e => {
                e.preventDefault ();
                props.setModalDepotID (key());
                props.setModalDepotAmount (stock[column.name]);
                props.setModalDepotVisible (true);
              }}
            >
              {stock[column.name] + column.sign} <FiEdit />
            </td>
          );
        } else {
          return (
            <td key={column.id} style={{width: props.width}}>
              {stock[column.name] + column.sign}
            </td>
          );
        }
      }
    }
  });

  const key = () => {
    if (props.stock.stock) {
      return props.stock.stock.id;
    } else {
      return props.stock.id;
    }
  };

  return (
    <LinkContainer key={key ()} to={`/stocks/${key ()}`}>
      <tr>
        <td style={{textAlign: 'left', width: props.widthName + '%'}}>
          {props.stock.name ? props.stock.name : props.stock.stock.name}
        </td>
        {stock}
      </tr>
    </LinkContainer>
  );
}
