import API from './Config';
import {errorAPI} from '../utils/ErrorHandling';

export const fetchEstimatesAPI = stockID => {
  return new Promise ((resolve, reject) => {
    API.get (`stocks/${stockID}/estimates`)
      .then (res => {
        var estimates = res.data;
        resolve (estimates);
      })
      .catch (error => {
        reject (errorAPI (error));
      });
  });
};
