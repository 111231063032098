import API from './Config';
import { errorAPI } from '../utils/ErrorHandling';

export const fetchLogsAPI = () => {
  return new Promise((resolve, reject) => {
    API.get(`logs`)
      .then(res => {
        var logs = res.data.reverse();
        resolve(logs);
      })
      .catch(error => {
        reject(errorAPI(error));
      });
  });
};

export const fetchStockLogsAPI = stockID => {
  return new Promise((resolve, reject) => {
    API.get(`logs/?stock_id=${stockID}`)
      .then(res => {
        var logs = res.data.reverse();
        resolve(logs);
      })
      .catch(error => {
        reject(errorAPI(error));
      });
  });
};

export const deleteLogsAPI = CSRF => {
  return new Promise((resolve, reject) => {
    API.delete(`logs`,
      {
        data: {
          csrf: CSRF
        }
      })
      .then(resolve(true))
      .catch(error => {
        reject(errorAPI(error));
      });
  });
};

export const deleteStockLogsAPI = (CSRF, stockID) => {
  return new Promise((resolve, reject) => {
    API.delete(`logs/?stock_id=${stockID}`,
      {
        data: {
          csrf: CSRF
        }
      })
      .then(resolve(true))
      .catch(error => {
        reject(errorAPI(error));
      });
  });
};
