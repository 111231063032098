import React, {useState} from 'react';
import {loginAPI} from '../api/Auth';
import {FormGroup, FormControl, Alert, Button} from 'react-bootstrap';
import './Login.css';

export default function Login (props) {
  const [username, setUsername] = useState ('');
  const [password, setPassword] = useState ('');
  const [error, setError] = useState ('');

  const handleSubmit = event => {
    event.preventDefault ();
    loginAPI (username, password)
      .then (csrf => {
        props.userHasAuthenticated (true);
        props.setCSRF(JSON.stringify (csrf).toString ().replace (/"/g, ''));
      })
      .catch (error => {
        setError (error.content.message);
      });
  };

  return (
    <div className="Login">
      <h5 className="Login-Title">
        <strong>Stock Screener.</strong> Please login.
      </h5>
      <form onSubmit={handleSubmit}>
        <FormGroup controlId="username">
          <FormControl
            autoFocus
            type="username"
            className="Login-Input"
            value={username}
            onChange={e => setUsername (e.target.value)}
            placeholder="Enter your username"
          />
        </FormGroup>
        <FormGroup controlId="password">
          <FormControl
            value={password}
            onChange={e => setPassword (e.target.value)}
            type="password"
            className="Login-Input"
            placeholder="Enter your password"
          />
        </FormGroup>
        {error &&
          <Alert className="Login-Alert" variant="danger">
            {error}
          </Alert>}
        <Button block className="Login-Button" type="submit">
          Login
        </Button>
      </form>
    </div>
  );
}
