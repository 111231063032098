import API from './Config';
import { errorAPI } from '../utils/ErrorHandling';

export const fetchDepotStocksAPI = () => {
  return new Promise((resolve, reject) => {
    API.get('depot')
      .then(res => {
        var stocks = res.data;
        stocks.depotStocks.sort((a, b) => b.value - a.value);
        resolve(stocks);
      })
      .catch(error => {
        reject(errorAPI(error));
      });
  });
};

export const addDepotStocksAPI = (CSRF,stockID, amount) => {
  return new Promise((resolve, reject) => {
    API.post(
      'depot',
      {
        csrf: CSRF,
        stock_id: stockID,
        amount: amount,
      }
    )
      .then(() => {
        resolve(true);
      })
      .catch(error => {
        reject(errorAPI(error));
      });
  });
};

export const updateDepotStocksAPI = (CSRF,stockID, amount) => {
  return new Promise((resolve, reject) => {
    API.put(
      'depot',
      {
        csrf: CSRF,
        stock_id: stockID,
        amount: amount,
      },
    )
      .then(() => {
        resolve(true);
      })
      .catch(error => {
        reject(errorAPI(error));
      });
  });
};

export const deleteDepotStocksAPI = (CSRF,stockID) => {
  return new Promise((resolve, reject) => {
    API.delete(`depot`, {
      data: {
        csrf: CSRF,
        stock_id: stockID,
      },
    })
      .then(resolve(true))
      .catch(error => {
        reject(errorAPI(error));
      });
  });
};
