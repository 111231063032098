import React, {useState, useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import Routes from './Routes';
import Navi from './components/Navi';
import columnsList from './utils/columns.json';
import './App.css';

function App (props) {
  const [isAuthenticating, setIsAuthenticating] = useState (true);
  const [isAuthenticated, userHasAuthenticated] = useState (false);
  const [currentSite, setCurrentSite] = useState ('');
  const [search, setSearch] = useState ('');
  const [columns, setColumns] = useState ([]);
  const [modalDepotVisible, setModalDepotVisible] = useState (false);
  const [modalDepotID, setModalDepotID] = useState ('');
  const [CSRF,setCSRF] = useState ('');

  useEffect (() => {
    onLoad ();
  }, []);

  useEffect (
    () => {
      if (currentSite === '/stocks') {
        setColumns (columnsList.types.stocks);
      }
      if (currentSite === '/depot') {
        setColumns (columnsList.types.depot);
      }
    },
    [currentSite]
  );

  async function onLoad () {
    const token = sessionStorage.getItem ('token');
    if (token != null) {
      userHasAuthenticated (true);
    }
    setIsAuthenticating (false);
  }

  function handleLogout () {
    sessionStorage.clear ();
    userHasAuthenticated (false);
    props.history.push ('/login');
  }

  function handleSearch (text) {
    setSearch (text);
  }

  return (
    !isAuthenticating &&
    <div className="App">
      {isAuthenticated
        ? <Navi
            handleLogout={handleLogout}
            search={search}
            handleSearch={handleSearch}
            columns={columns}
            setColumns={setColumns}
            currentSite={currentSite}
            setModalDepotID={setModalDepotID}
            setModalDepotVisible={setModalDepotVisible}
          />
        : null}
      <div>
        <Routes
          appProps={{
            isAuthenticated,
            userHasAuthenticated,
            handleLogout,
            search,
            columns,
            setCurrentSite,
            modalDepotVisible,
            setModalDepotVisible,
            modalDepotID,
            setModalDepotID,
            CSRF,
            setCSRF,
          }}
        />
      </div>
    </div>
  );
}

export default withRouter (App);
