import React, {useState} from 'react';
import {Table} from 'react-bootstrap';
import StockTableEntry from './StockTableEntry';
import './StockTable.css';

export default function StockTable (props) {
  const [tableHeaderHeight, setTableHeaderHeight] = useState (101);

  const getColumnsLength = () => {
    var count = 0;
    props.columns.forEach (column => {
      if (column.visible === true) count = count + 1;
    });
    return count;
  };

  let widthName = 100 - 88;
  let width = (100 - widthName) / getColumnsLength () + '%';

  return (
    <Table
      responsive="sm"
      style={{marginTop: tableHeaderHeight}}
      className="Stocks-Table"
      striped
      hover
    >
      <thead
        ref={el => {
          if (!el) return;
          setTableHeaderHeight (el.getBoundingClientRect ().height + 56);
        }}
      >
        <tr>
          <th
            onClick={() => {
              props.sortStocks ('name');
            }}
            style={{textAlign: 'left', width: widthName + '%'}}
          >
            Name
          </th>
          {props.columns.map (
            column =>
              column.visible &&
              <th
                key={column.id}
                style={{width: width}}
                onClick={() => {
                  props.sortStocks (
                    column.name,
                    column.path.split ('.').length
                  );
                }}
              >
                {column.output}
              </th>
          )}
        </tr>
      </thead>
      <tbody>
        {props.stocks.length > 0 &&
          props.stocks.map (stock => (
            <StockTableEntry
              key={stock.id}
              stock={stock}
              columns={props.columns}
              width={width}
              widthName={widthName}
              setModalDepotVisible={props.setModalDepotVisible}
              setModalDepotID={props.setModalDepotID}
              setModalDepotAmount={props.setModalDepotAmount}
            />
          ))}
      </tbody>
    </Table>
  );
}
