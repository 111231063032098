import React from 'react';
import {OverlayTrigger, Popover} from 'react-bootstrap';
import './AdjustmentTooltip.css';

export default function AdjustmentTooltip (props) {
  let real_value = '';
  let value = props.value;
  let adjustmentValue = props.adjustment.value_eur;

  if (props.adjustment.field.name === 'pe_ratio') {
    adjustmentValue = '';
    real_value = props.value;
    value = '';
  }

  if (props.adjustment.field.name === 'profit_growth') {
    adjustmentValue = '';
    real_value = props.value + '%';
    value = '';
  }

  if (!value && !adjustmentValue && !props.adjustment.comment) {
    return value
      ? <td
          onClick={props.handleClick}
          style={{cursor: 'default', color: '#FF851B'}}
          key={props.year}
        >
          {adjustmentValue} €*
        </td>
      : <td
          onClick={props.handleClick}
          style={{cursor: 'default', color: '#FF851B'}}
          key={props.year}
        >
          {real_value}*
        </td>;
  } else {
    return (
      <OverlayTrigger
        key={props.year}
        placement="top"
        overlay={
          <Popover className="adjustment-tooltip" id="popover-basic">
            <Popover.Content>
              {value && <div><b>Real value:</b> {value} €<br /></div>}
              {adjustmentValue &&
                <div><b>Adjusted value:</b> {adjustmentValue} €<br /></div>}
              {props.adjustment.comment &&
                <div><b> Comment: </b> {props.adjustment.comment}</div>}
            </Popover.Content>
          </Popover>
        }
      >
        {value
          ? <td onClick={props.handleClick} style={{cursor: 'default', color: '#FF851B'}}>
              {adjustmentValue} €*
            </td>
          : <td onClick={props.handleClick} style={{cursor: 'default', color: '#FF851B'}}>
              {real_value}*
            </td>}
      </OverlayTrigger>
    );
  }
}
