import React, {useState, useEffect} from 'react';
import Select from 'react-select';
import {
  addDepotStocksAPI,
  updateDepotStocksAPI,
  deleteDepotStocksAPI,
} from '../api/Depot';
import {Modal, Button, InputGroup, FormControl, Alert} from 'react-bootstrap';
import './DepotModal.css';

export default function ChartModal (props) {
  const [error, setError] = useState ();
  const [stock, setStock] = useState ();
  const [amount, setAmount] = useState ();
  const [state, setState] = useState ();
  const [onDelete, setOnDelete] = useState (false);
  const [isDisabled, setDisabled] = useState (false);
  const [title, setTitle] = useState ('');

  const stocks = props.stocks
    .sort ((a, b) => a.name.localeCompare (b.name))
    .map (function (stock) {
      return {value: stock.id, label: stock.name + ' (' + stock.id + ')'};
    });

  useEffect (
    () => {
      prepare ();
    },
    [props]
  );

  const prepare = () => {
    setOnDelete (false);
    setError ('');
    if (props.ID === 'new') {
      setState ('new');
      setStock ();
      setAmount (0);
      setDisabled (false);
      setTitle ('Add Stock to Depot')
    } else {
      setState ('update');
      setStock (props.ID);
      setAmount (props.amount);
      setDisabled (true);
      setTitle ('Update Stock in Depot')
    }
  };

  const refreshData = () => {
    props.fetchDepotStocks ();
  };

  const handleSubmit = event => {
    event.preventDefault ();
    if (onDelete) {
      deleteDepotStocksAPI (props.CSRF,stock)
        .then (() => {
          refreshData ();
          props.handleClose ();
        })
        .catch (error => {
          if (error.type === 'API' && error.content.code === 401) {
            props.handleLogout ();
          } else {
            setError (error.content.message);
          }
        });
    } else {
      if (state === 'new') {
        addDepotStocksAPI (props.CSRF,stock, amount)
          .then (() => {
            refreshData ();
            props.handleClose ();
          })
          .catch (error => {
            if (error.type === 'API' && error.content.code === 401) {
              props.handleLogout ();
            } else {
              setError (error.content.message);
            }
          });
      }
      if (state === 'update') {
        updateDepotStocksAPI (props.CSRF,stock, amount)
          .then (() => {
            refreshData ();
            props.handleClose ();
          })
          .catch (error => {
            if (error.type === 'API' && error.content.code === 401) {
              props.handleLogout ();
            } else {
              setError (error.content.message);
            }
          });
      }
    }
  };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      height: '32px',
      minHeight: '32px',
    }),
  };

  return (
    <div>
      {state &&
        <Modal show={props.show} onHide={props.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <InputGroup className="mb-3 options">
              <Select
                placeholder="Search stock..."
                options={stocks}
                styles={customStyles}
                value={stocks.filter (option => option.value === stock)}
                onChange={selectedOption => {
                  setStock (selectedOption.value);
                }}
                isDisabled={isDisabled}
              />
            </InputGroup>
            <InputGroup size="sm" className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text id="inputGroup-sizing-sm">
                  Amount*
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
                value={amount}
                onChange={e => setAmount (e.target.value)}
              />
            </InputGroup>
            {error &&
              <Alert className="adjustment-modal-alert" variant="danger">
                {error}
              </Alert>}
          </Modal.Body>
          {state === 'new'
            ? <Modal.Footer>
                <Button variant="primary" onClick={handleSubmit}>
                  Add stock
                </Button>
                <Button variant="secondary" onClick={props.handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            : <Modal.Footer>
                {onDelete === true
                  ? <Button onClick={handleSubmit} variant="primary">
                      Are you sure?
                    </Button>
                  : <Button variant="danger" onClick={() => setOnDelete (true)}>
                      Remove stock
                    </Button>}
                {onDelete === true
                  ? <Button
                      onClick={() => setOnDelete (false)}
                      variant="primary"
                    >
                      Cancel
                    </Button>
                  : <Button variant="primary" onClick={handleSubmit}>
                      Update stock
                    </Button>}
                <Button variant="secondary" onClick={props.handleClose}>
                  Close
                </Button>
              </Modal.Footer>}
        </Modal>}
    </div>
  );
}
