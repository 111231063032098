import React, {useState} from 'react';
import {Table, OverlayTrigger, Popover} from 'react-bootstrap';
import AdjustmentTooltip from './AdjustmentTooltip';
import ChartModal from './ChartModal';
import AdjustmentModal from './AdjustmentModal';
import './StockDetail.css';
import {FiBarChart} from 'react-icons/fi';

export default function StockDetail (props) {
  const [showChartModal, setShowChartModal] = useState (false);
  const [showAdjustmentModal, setShowAdjustmentModal] = useState (false);
  const [modalKey, setModalKey] = useState ();
  const [modalFieldID, setModalFieldID] = useState ();
  const [modalEntry, setModalEntry] = useState ();

  const handleCloseChartModal = () => setShowChartModal (false);
  const handleCloseAdjustmentModal = () => setShowAdjustmentModal (false);

  const handleShowChartModal = modalKey => {
    setModalKey (modalKey);
    setShowChartModal (true);
  };
  const handleShowAdjustmentModal = (modalFieldID, modalEntry) => {
    setModalFieldID (modalFieldID);
    setModalEntry (modalEntry);
    setShowAdjustmentModal (true);
  };

  const getAdjustment = (entries, field) => {
    let adjustment = false;
    entries.forEach (entry => {
      if (entry.field.name === field) {
        adjustment = entry;
      }
    });
    return adjustment;
  };

  return (
    <React.Fragment>
      <ChartModal
        show={showChartModal}
        handleClose={handleCloseChartModal}
        stock={props.stock}
        histories={props.histories}
        estimates={props.estimates}
        modalKey={modalKey}
      />
      <AdjustmentModal
        show={showAdjustmentModal}
        handleClose={handleCloseAdjustmentModal}
        entry={modalEntry}
        fieldID={modalFieldID}
        fetchHistories={props.fetchHistories}
        fetchEstimates={props.fetchEstimates}
        CSRF={props.CSRF}
      />
      <Table className="Stock-Table" responsive="sm" striped hover>
        <thead>
          <tr>
            <th style={{textAlign: 'left'}}>Fields</th>
            <th>Calculation</th>
            <th style={{textAlign: 'left'}}>Fields</th>
            {props.histories.length > 0 &&
              props.histories.map (history => (
                <th key={history.year}>{history.year}</th>
              ))}
            {props.estimates.length > 0 &&
              props.estimates.map (estimate => (
                <th key={estimate.year}>{estimate.year}</th>
              ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <th style={{textAlign: 'left'}}>Price</th>
            <td>{props.stock.calculation.current_price} €</td>
            <th
              onClick={e => {
                e.preventDefault ();
                handleShowChartModal ('price_eur');
              }}
              style={{
                textAlign: 'left',
                cursor: 'default',
              }}
            >
              <FiBarChart /> Price{' '}
            </th>
            {props.histories.length > 0 &&
              props.histories.map (history => (
                <td key={history.year}>{history.price_eur} €</td>
              ))}
            {props.estimates.length > 0 &&
              props.estimates.map (estimate => (
                <td key={estimate.year}>{estimate.price_eur} €</td>
              ))}
          </tr>
          <tr>
            <th style={{textAlign: 'left'}}>EPS + 365d</th>
            <td>{props.stock.calculation.eps} €</td>
            <th
              onClick={e => {
                e.preventDefault ();
                handleShowChartModal ('eps_eur');
              }}
              style={{
                textAlign: 'left',
                cursor: 'default',
              }}
            >
              <FiBarChart /> EPS
            </th>
            {props.histories.map (history => {
              let adjustment = getAdjustment (
                history.adjusted_histories,
                'eps'
              );
              return adjustment
                ? <AdjustmentTooltip
                    key={history.year}
                    year={history.year}
                    value={history.eps_eur}
                    adjustment={adjustment}
                    handleClick={e => {
                      e.preventDefault ();
                      handleShowAdjustmentModal (1, history);
                    }}
                  />
                : <td
                    onClick={e => {
                      e.preventDefault ();
                      handleShowAdjustmentModal (1, history);
                    }}
                    style={{cursor: 'default'}}
                    key={history.year}
                  >
                    {history.eps_eur} €
                  </td>;
            })}
            {props.estimates.map (estimate => {
              let adjustment = getAdjustment (
                estimate.adjusted_estimates,
                'eps'
              );
              return adjustment
                ? <AdjustmentTooltip
                    key={estimate.year}
                    year={estimate.year}
                    value={estimate.eps_eur}
                    adjustment={adjustment}
                    handleClick={e => {
                      e.preventDefault ();
                      handleShowAdjustmentModal (1, estimate);
                    }}
                  />
                : <td
                    onClick={e => {
                      e.preventDefault ();
                      handleShowAdjustmentModal (1, estimate);
                    }}
                    style={{cursor: 'default'}}
                    key={estimate.year}
                  >
                    {estimate.eps_eur} €
                  </td>;
            })}
          </tr>
          <tr>
            <th style={{textAlign: 'left'}}>Dividend + 365d</th>
            <td>{props.stock.calculation.dividend} €</td>
            <th
              onClick={e => {
                e.preventDefault ();
                handleShowChartModal ('dividend_eur');
              }}
              style={{
                textAlign: 'left',
                cursor: 'default',
              }}
            >
              <FiBarChart /> Dividend
            </th>
            {props.histories.map (history => {
              let adjustment = getAdjustment (
                history.adjusted_histories,
                'dividend'
              );
              return adjustment
                ? <AdjustmentTooltip
                    key={history.year}
                    year={history.year}
                    value={history.dividend_eur}
                    adjustment={adjustment}
                    handleClick={e => {
                      e.preventDefault ();
                      handleShowAdjustmentModal (2, history);
                    }}
                  />
                : <td
                    onClick={e => {
                      e.preventDefault ();
                      handleShowAdjustmentModal (2, history);
                    }}
                    style={{cursor: 'default'}}
                    key={history.year}
                  >
                    {history.dividend_eur} €
                  </td>;
            })}
            {props.estimates.map (estimate => {
              let adjustment = getAdjustment (
                estimate.adjusted_estimates,
                'dividend'
              );
              return adjustment
                ? <AdjustmentTooltip
                    key={estimate.year}
                    year={estimate.year}
                    value={estimate.dividend_eur}
                    adjustment={adjustment}
                    handleClick={e => {
                      e.preventDefault ();
                      handleShowAdjustmentModal (2, estimate);
                    }}
                  />
                : <td
                    onClick={e => {
                      e.preventDefault ();
                      handleShowAdjustmentModal (2, estimate);
                    }}
                    style={{cursor: 'default'}}
                    key={estimate.year}
                  >
                    {estimate.dividend_eur} €
                  </td>;
            })}
          </tr>
          <tr>
            <th style={{textAlign: 'left'}}>Dividend yield</th>
            <td>{props.stock.calculation.dividend_yield}%</td>
            <th
              onClick={e => {
                e.preventDefault ();
                handleShowChartModal ('payout_ratio');
              }}
              style={{
                textAlign: 'left',
                cursor: 'default',
              }}
            >
              <FiBarChart /> Payout ratio
            </th>
            {props.histories.length > 0 &&
              props.histories.map (history => (
                <td key={history.year}>{history.payout_ratio}%</td>
              ))}
            {props.estimates.length > 0 &&
              props.estimates.map (estimate => (
                <td key={estimate.year}>{estimate.payout_ratio}%</td>
              ))}
          </tr>
          <tr>
            <th style={{textAlign: 'left'}}>Max. drop in profits</th>
            <td>{props.stock.calculation.max_drop}%</td>
            <th
              onClick={e => {
                e.preventDefault ();
                handleShowChartModal ('profit_growth');
              }}
              style={{
                textAlign: 'left',
                cursor: 'default',
              }}
            >
              <FiBarChart /> Profit growth
            </th>
            {props.histories.map (history => {
              let adjustment = getAdjustment (
                history.adjusted_histories,
                'profit_growth'
              );
              return adjustment
                ? <AdjustmentTooltip
                    key={history.year}
                    year={history.year}
                    value={history.profit_growth}
                    adjustment={adjustment}
                    handleClick={e => {
                      e.preventDefault ();
                      handleShowAdjustmentModal (4, history);
                    }}
                  />
                : <td
                    onClick={e => {
                      e.preventDefault ();
                      handleShowAdjustmentModal (4, history);
                    }}
                    style={{cursor: 'default'}}
                    key={history.year}
                  >
                    {history.profit_growth}%
                  </td>;
            })}
            {props.estimates.map (estimate => {
              let adjustment = getAdjustment (
                estimate.adjusted_estimates,
                'profit_growth'
              );
              return adjustment
                ? <AdjustmentTooltip
                    key={estimate.year}
                    year={estimate.year}
                    value={estimate.profit_growth}
                    adjustment={adjustment}
                    handleClick={e => {
                      e.preventDefault ();
                      handleShowAdjustmentModal (4, estimate);
                    }}
                  />
                : <td
                    onClick={e => {
                      e.preventDefault ();
                      handleShowAdjustmentModal (4, estimate);
                    }}
                    style={{cursor: 'default'}}
                    key={estimate.year}
                  >
                    {estimate.profit_growth}%
                  </td>;
            })}
          </tr>
          <tr>
            <th style={{textAlign: 'left'}}>Current P/E ratio</th>
            <td>{props.stock.calculation.current_pe_ratio}</td>
            <th
              onClick={e => {
                e.preventDefault ();
                handleShowChartModal ('pe_ratio');
              }}
              style={{
                textAlign: 'left',
                cursor: 'default',
              }}
            >
              <FiBarChart /> P/E ratio
            </th>
            {props.histories.map (history => {
              let adjustment = getAdjustment (
                history.adjusted_histories,
                'pe_ratio'
              );
              return adjustment
                ? <AdjustmentTooltip
                    key={history.year}
                    year={history.year}
                    value={history.pe_ratio}
                    adjustment={adjustment}
                    handleClick={e => {
                      e.preventDefault ();
                      handleShowAdjustmentModal (3, history);
                    }}
                  />
                : <td
                    onClick={e => {
                      e.preventDefault ();
                      handleShowAdjustmentModal (3, history);
                    }}
                    style={{cursor: 'default'}}
                    key={history.year}
                  >
                    {history.pe_ratio}
                  </td>;
            })}
            {props.estimates.map (estimate => {
              let adjustment = getAdjustment (
                estimate.adjusted_estimates,
                'pe_ratio'
              );
              return adjustment
                ? <AdjustmentTooltip
                    key={estimate.year}
                    year={estimate.year}
                    value={estimate.pe_ratio}
                    adjustment={adjustment}
                    handleClick={e => {
                      e.preventDefault ();
                      handleShowAdjustmentModal (3, estimate);
                    }}
                  />
                : <td
                    onClick={e => {
                      e.preventDefault ();
                      handleShowAdjustmentModal (3, estimate);
                    }}
                    style={{cursor: 'default'}}
                    key={estimate.year}
                  >
                    {estimate.pe_ratio}
                  </td>;
            })}
          </tr>
          <tr>
            <th style={{textAlign: 'left'}}>Average P/E ratio</th>

            <OverlayTrigger
              placement="top"
              overlay={
                <Popover id="popover-basic">
                  <Popover.Content>
                    Median: {props.stock.calculation.median_pe_ratio}
                  </Popover.Content>
                </Popover>
              }
            >
              <td>{props.stock.calculation.average_pe_ratio}</td>
            </OverlayTrigger>

            {props.stock.currency.currency_symbol !== 'EUR'
              ? <th
                  onClick={e => {
                    e.preventDefault ();
                    handleShowChartModal ('currency');
                  }}
                  style={{
                    textAlign: 'left',
                    cursor: 'default',
                  }}
                >
                  <FiBarChart /> {props.stock.currency.currency_symbol} in EUR
                </th>
              : <th />}
            {props.histories.length > 0 &&
              props.histories.map (
                history =>
                  props.stock.currency.currency_symbol !== 'EUR'
                    ? props.stock.currency.currency_symbol !== 'JPY'
                        ? <td key={history.year}>
                            {parseFloat (
                              history.stock.currency.currency_histories[0]
                                .rate_in_eur
                            ).toFixed (2)}
                            {' '}
                            €
                          </td>
                        : <td key={history.year}>
                            {parseFloat (
                              history.stock.currency.currency_histories[0]
                                .rate_in_eur
                            )}
                            {' '}
                            €
                          </td>
                    : <td key={history.year}> </td>
              )}
            {props.estimates.length > 0 &&
              props.estimates.map (
                estimate =>
                  props.stock.currency.currency_symbol !== 'EUR'
                    ? props.stock.currency.currency_symbol !== 'JPY'
                        ? <td key={estimate.year}>
                            {estimate.stock.currency.currency_histories[0]
                              ? parseFloat (
                                  estimate.stock.currency.currency_histories[0]
                                    .rate_in_eur
                                ).toFixed (2)
                              : parseFloat (
                                  estimate.stock.currency.rate_in_eur
                                ).toFixed (2)}
                            {' '}
                            €
                          </td>
                        : <td key={estimate.year}>
                            {estimate.stock.currency.currency_histories[0]
                              ? parseFloat (
                                  estimate.stock.currency.currency_histories[0]
                                    .rate_in_eur
                                )
                              : parseFloat (
                                  estimate.stock.currency.rate_in_eur
                                )}
                            {' '}
                            €
                          </td>
                    : <td key={estimate.year}> </td>
              )}
          </tr>
          <tr>
            <th style={{textAlign: 'left'}}>Noise</th>
            <td>{props.stock.calculation.noise}%</td>
            <th style={{textAlign: 'left'}} />
            {props.histories.length > 0 &&
              props.histories.map (history => <td key={history.year} />)}
            {props.estimates.length > 0 &&
              props.estimates.map (estimate => <td key={estimate.year} />)}
          </tr>
          <tr>
            <th style={{textAlign: 'left'}}>P/E Comparison</th>
            <td>{props.stock.calculation.pe_comparsion}%</td>
            <th style={{textAlign: 'left'}} />
            {props.histories.length > 0 &&
              props.histories.map (history => <td key={history.year} />)}
            {props.estimates.length > 0 &&
              props.estimates.map (estimate => <td key={estimate.year} />)}
          </tr>
          <tr>
            <th style={{textAlign: 'left'}}>Profit growth short</th>
            <td>{props.stock.calculation.profit_growth_short_term}%</td>
            <th style={{textAlign: 'left'}} />
            {props.histories.length > 0 &&
              props.histories.map (history => <td key={history.year} />)}
            {props.estimates.length > 0 &&
              props.estimates.map (estimate => <td key={estimate.year} />)}
          </tr>
          <tr>
            <th style={{textAlign: 'left'}}>Profit growth mid</th>
            <td>{props.stock.calculation.profit_growth_mid_term}%</td>
            <th style={{textAlign: 'left'}} />
            {props.histories.length > 0 &&
              props.histories.map (history => <td key={history.year} />)}
            {props.estimates.length > 0 &&
              props.estimates.map (estimate => <td key={estimate.year} />)}
          </tr>
          <tr>
            <th style={{textAlign: 'left'}}>Profit growth long</th>
            <td>{props.stock.calculation.profit_growth_long_term}%</td>
            <th style={{textAlign: 'left'}} />
            {props.histories.length > 0 &&
              props.histories.map (history => <td key={history.year} />)}
            {props.estimates.length > 0 &&
              props.estimates.map (estimate => <td key={estimate.year} />)}
          </tr>
          <tr>
            <th style={{textAlign: 'left'}}>Bond Yield 4.5 years</th>
            <td>{props.stock.calculation.bond_yield}%</td>
            <th style={{textAlign: 'left'}} />
            {props.histories.length > 0 &&
              props.histories.map (history => <td key={history.year} />)}
            {props.estimates.length > 0 &&
              props.estimates.map (estimate => <td key={estimate.year} />)}
          </tr>
        </tbody>
      </Table>
    </React.Fragment>
  );
}
