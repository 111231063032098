import API from './Config';
import {
  errorAPI,
  errorRequired,
  errorAPIWrongLogin,
} from '../utils/ErrorHandling';

export const loginAPI = (username, password) => {
  return new Promise((resolve, reject) => {
    if (username.length > 0) {
      if (password.length > 0) {
        API.post('auth/login', {
          username: username,
          password: password,
        })
          .then(res => {
            var csrf = res.data.csrf;
            resolve(csrf);
          })
          .catch(error => {
            if (error.response.status === 403) {
              reject(errorAPIWrongLogin(error));
            } else {
              reject(errorAPI(error));
            }
          });
      } else {
        reject(errorRequired('Password'));
      }
    } else {
      reject(errorRequired('Username'));
    }
  });
};

export const logoutAPI = (CSRF) => {
  return new Promise((resolve, reject) => {
    API.post('auth/login', {
      csrf: CSRF
    })
      .then(() => {
        resolve(true);
      })
      .catch(error => {
        if (error.response.status === 403) {
          reject(errorAPIWrongLogin(error));
        } else {
          reject(errorAPI(error));
        }
      });
  });
};
