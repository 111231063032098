import React from 'react';
import {Switch, Redirect} from 'react-router-dom';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import UnauthenticatedRoute from './components/UnauthenticatedRoute';
import Login from './containers/Login';
import Dashboard from './containers/Dashboard';
import Stocks from './containers/Stocks';
import Stock from './containers/Stock';
import Depot from './containers/Depot';
import NotFound from './containers/NotFound';

export default function Routes({appProps},props) {
  return (
    <Switch>
      <AuthenticatedRoute path="/" exact appProps={appProps}>
        <Redirect to={'/dashboard'} />
      </AuthenticatedRoute>
      <AuthenticatedRoute
        path="/dashboard"
        exact
        component={Dashboard}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/stocks"
        exact
        component={Stocks}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/stocks/:id"
        exact
        component={Stock}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/depot"
        exact
        component={Depot}
        appProps={appProps}
      />
      <UnauthenticatedRoute
        path="/login"
        exact
        component={Login}
        appProps={appProps}
      />
      {/* Finally, catch all unmatched routes */}
      <AuthenticatedRoute exact component={NotFound} appProps={appProps} />
    </Switch>
  );
}
