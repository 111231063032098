export const errorAPI = errorAPI => {
  return {
    type: 'API',
    content: {
      code: errorAPI.response.status,
      message: errorAPI.response.data[0].msg,
    },
  };
};

export const errorAPIWrongLogin = errorAPI => {
  return {
    type: 'API',
    content: {
      code: errorAPI.response.status,
      message: 'Username or password was wrong!',
    },
  };
};

export const errorRequired = name => {
  return {
    type: 'Input',
    content: {
      message: `${name} is required!`,
    },
  };
};
