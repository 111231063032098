import React from 'react';
import {DropdownButton, Dropdown, ButtonGroup} from 'react-bootstrap';
import {MdVisibility, MdVisibilityOff} from 'react-icons/md';
import './ColumnDropdown.css';

export default function ColumnDropdown (props) {
  const changeVisible = id => {
    const temp = [...props.columns];
    const tempEntriy = temp[id];
    tempEntriy.visible = !tempEntriy.visible;
    temp[id] = tempEntriy;
    props.setColumns (temp);
  };

  const changeAllToVisible = () => {
    const temp = [...props.columns];
    props.columns.forEach(column => {
      var tempEntriy = column;
      tempEntriy.visible = true;
      temp[column.id] = tempEntriy;
    });
    props.setColumns (temp);
  };

  return (
    <DropdownButton
      variant="light"
      className="column-dropdown"
      as={ButtonGroup}
      title="Columns"
      alignRight
      id="dropdown-menu-align-right"
    >
      {props.columns &&
        props.columns.map (column => (
          <Dropdown.Item
            onClick={() => changeVisible (column.id)}
            key={column.id}
            eventKey={column.id}
          >
            {!column.visible ? <MdVisibilityOff /> : <MdVisibility />} {' '}
            {column.output}
          </Dropdown.Item>
        ))}

      <Dropdown.Divider />
      <Dropdown.Item onClick={() => changeAllToVisible ()}>
        Show all
      </Dropdown.Item>
    </DropdownButton>
  );
}
