import API from './Config';
import { errorAPI } from '../utils/ErrorHandling';

export const fetchStocksAPI = () => {
  return new Promise((resolve, reject) => {
    API.get('stocks')
      .then(res => {
        var stocks = res.data;
        stocks.sort(
          (a, b) =>
            b.calculation.overall_ranking - a.calculation.overall_ranking
        );
        resolve(stocks);
      })
      .catch(error => {
        reject(errorAPI(error));
      });
  });
};

export const fetchStockAPI = stockID => {
  return new Promise((resolve, reject) => {
    API.get(`stocks/${stockID}`)
      .then(res => {
        var stock = res.data;
        resolve(stock);
      })
      .catch(error => {
        reject(errorAPI(error));
      });
  });
};
