import React, {useState, useEffect} from 'react';
import {deleteStockLogsAPI} from '../api/Logs';
import {Modal, Button, Alert} from 'react-bootstrap';
import Moment from 'moment';
import './LogsModal.css';

export default function LogsModal (props) {
  const [onDelete, setOnDelete] = useState (false);
  const [error, setError] = useState ();

  const showDialog = () => {
    setOnDelete (true);
  };

  const removeLogs = () => {
    deleteStockLogsAPI (props.CSRF,props.stock.id)
      .then (() => {
        props.fetchLogs ();
        props.handleClose ();
      })
      .catch (error => {
        if (error.type === 'API' && error.content.code === 401) {
          props.handleLogout ();
        } else {
          setError (error.content.message);
        }
      });
  };

  useEffect (
    () => {
      setOnDelete (false);
    },
    [props.show]
  );

  Moment.locale ('de');
  const logs = props.logs.map (function (log) {
    return (
      <div key={log.id}>
        {Moment (log.date).format ('MM/DD/YYYY hh:mm A') + ' - ' + log.message}
      </div>
    );
  });

  return (
    <Modal size="lg" show={props.show} onHide={props.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Logs</Modal.Title>
      </Modal.Header>
      <Modal.Body className="logs-modal">
        <div className="logs-modal-textarea">
          {logs}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {error &&
          <Alert className="logs-modal-alert" variant="danger">
            {error}
          </Alert>}
        {!onDelete
          ? <Button variant="danger" onClick={showDialog}>
              Remove logs
            </Button>
          : <Button variant="primary" onClick={removeLogs}>
              Are you sure?
            </Button>}
        <Button variant="secondary" onClick={props.handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
