import React from 'react';
import {Nav, Navbar, Button, Form, FormControl} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';
import ColumnDropdown from './ColumnDropdown.js';
import {MdPlaylistAdd, MdNoteAdd} from 'react-icons/md';
import './Navi.css';

export default function Navi (props) {
  return (
    <Navbar bg="dark" variant="dark" className="Navi fixed-top">
      <Navbar.Brand>Stock Screener</Navbar.Brand>
      <Nav className="mr-auto">
        <LinkContainer exact to="/dashboard">
          <Nav.Link onClick={() => props.handleSearch ('')}>Dashboard</Nav.Link>
        </LinkContainer>
        <LinkContainer exact to="/stocks">
          <Nav.Link onClick={() => props.handleSearch ('')}>Stocks</Nav.Link>
        </LinkContainer>
        <LinkContainer exact to="/depot">
          <Nav.Link onClick={() => props.handleSearch ('')}>Depot</Nav.Link>
        </LinkContainer>
      </Nav>
      <Form inline>
        {/*props.currentSite === '/stocks' &&
          <Button variant="info" style={{marginRight: '.5rem'}}>
            <MdPlaylistAdd />
  </Button>*/}
        {props.currentSite === '/depot' &&
          <Button
            variant="info"
            style={{marginRight: '.5rem'}}
            onClick={e => {
              e.preventDefault ();
              props.setModalDepotID ('new');
              props.setModalDepotVisible (true);
            }}
          >
            <MdPlaylistAdd />
          </Button>}
        {(props.currentSite === '/stocks' || props.currentSite === '/depot') &&
          <FormControl
            type="text"
            placeholder="Search..."
            className="mr-sm-2 Navi-Input"
            value={props.search}
            onChange={e => props.handleSearch (e.target.value)}
          />}
        {props.currentSite === '/stocks' &&
          <ColumnDropdown
            columns={props.columns}
            setColumns={props.setColumns}
          />}
        {props.currentSite === '/depot' &&
          <ColumnDropdown
            columns={props.columns}
            setColumns={props.setColumns}
          />}
        <Button
          className="Navi-Button"
          variant="outline-light"
          onClick={props.handleLogout}
        >
          Logout
        </Button>
      </Form>
    </Navbar>
  );
}
