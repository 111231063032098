import React, {useState, useEffect} from 'react';
import {
  Alert,
  Spinner,
  Nav,
  ButtonGroup,
  Button,
  DropdownButton,
  Dropdown,
} from 'react-bootstrap';
import {fetchStockAPI} from '../api/Stocks';
import {fetchHistoriesAPI} from '../api/Histories';
import {fetchEstimatesAPI} from '../api/Estimates';
import {fetchStockLogsAPI} from '../api/Logs';
import StockDetail from '../components/StockDetail';
import LogsModal from '../components/LogsModal';
import Moment from 'moment';
import './Stock.css';

export default function Stock (props) {
  const [stock, setStock] = useState ([]);
  const [stockIsLoading, setStockIsLoading] = useState (true);
  const [histories, setHistories] = useState ([]);
  const [historiesAreLoading, setHistoriesAreLoading] = useState (true);
  const [estimates, setEstimates] = useState ([]);
  const [estimatesAreLoading, setEstimatesAreLoading] = useState (true);
  const [logs, setLogs] = useState ([]);
  const [logsAreLoading, setlogsAreLoading] = useState (true);
  const [error, setError] = useState ('');
  const [show, setShow] = useState (false);

  const handleClose = () => setShow (false);
  const handleShow = () => setShow (true);

  const fetchStock = async () => {
    await fetchStockAPI (props.match.params.id)
      .then (stock => {
        setStock (stock);
        setStockIsLoading (false);
      })
      .catch (error => {
        if (error.type === 'API' && error.content.code === 401) {
          props.handleLogout ();
        } else {
          setError (error.content.message);
          setStockIsLoading (false);
        }
      });
  };

  const fetchHistories = async () => {
    await fetchHistoriesAPI (props.match.params.id)
      .then (histories => {
        setHistories (histories);
        setHistoriesAreLoading (false);
      })
      .catch (error => {
        console.log(error);
        setError (error.content.message);
        setHistoriesAreLoading (false);
      });
  };

  const fetchEstimates = async () => {
    await fetchEstimatesAPI (props.match.params.id)
      .then (estimates => {
        setEstimates (estimates);
        setEstimatesAreLoading (false);
      })
      .catch (error => {
        setError (error.content.message);
        setEstimatesAreLoading (false);
      });
  };

  const fetchLogs = async () => {
    await fetchStockLogsAPI (props.match.params.id)
      .then (logs => {
        setLogs (logs);
        setlogsAreLoading (false);
      })
      .catch (error => {
        setError (error.content.message);
        setlogsAreLoading (false);
      });
  };

  useEffect (() => {
    fetchStock ();
    fetchHistories ();
    fetchEstimates ();
    fetchLogs ();
  }, []);

  Moment.locale ('de');
  return (
    <div className="Stock">
      {stockIsLoading ||
        historiesAreLoading ||
        estimatesAreLoading ||
        logsAreLoading
        ? <div className="text-center">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        : error
            ? <Alert className="text-center" variant="danger">
                {error}
              </Alert>
            : <div>
                <Nav>
                  <Nav.Item>
                    <h4>
                      {stock.name}
                    </h4>
                  </Nav.Item>
                  <Nav.Item className="ml-auto">
                    <ButtonGroup size="sm">
                      <Button variant="secondary" disabled>
                        {Moment (stock.fiscal_year,'M').format (
                          'MMMM'
                        )}
                      </Button>
                      <Button variant="secondary" disabled>
                        {stock.currency.currency_symbol}
                      </Button>
                      <Button variant="secondary" disabled>
                        Last updated on
                        {Moment (stock.calculation.last_update).format (
                          ' MM/DD/YYYY hh:mm A'
                        )}
                      </Button>
                      <DropdownButton
                        title="External sites "
                        as={ButtonGroup}
                        variant="dark"
                        size="sm"
                      >
                        <Dropdown.Item
                          style={{fontSize: '.875rem'}}
                          onClick={e => {
                            e.preventDefault ();
                            window.open (
                              'https://de.finance.yahoo.com/q?s=' +
                                stock.symbol,
                              '_blank'
                            );
                          }}
                        >
                          Yahoo
                        </Dropdown.Item>
                        <Dropdown.Item
                          style={{fontSize: '.875rem'}}
                          onClick={e => {
                            e.preventDefault ();
                            window.open (stock.link, '_blank');
                          }}
                        >
                          MarketScreener
                        </Dropdown.Item>
                        <Dropdown.Item
                          style={{fontSize: '.875rem'}}
                          onClick={e => {
                            e.preventDefault ();
                            window.open (
                              'https://financials.morningstar.com/ratios/r.html?t=' +
                                stock.symbol.split ('.')[0],
                              '_blank'
                            );
                          }}
                        >
                          Morningstar
                        </Dropdown.Item>
                      </DropdownButton>
                      <Button
                        variant={logs.length > 0 ? 'danger' : 'success'}
                        onClick={logs.length > 0 ? handleShow : null}
                        disabled={logs.length > 0 ? false : true}
                      >
                        Logs: {logs.length}
                      </Button>
                    </ButtonGroup>
                  </Nav.Item>
                </Nav>
                <LogsModal
                  stock={stock}
                  logs={logs}
                  show={show}
                  handleClose={handleClose}
                  fetchLogs={fetchLogs}
                  CSRF={props.CSRF}
                />
                <StockDetail
                  stock={stock}
                  histories={histories}
                  estimates={estimates}
                  fetchHistories={fetchHistories}
                  fetchEstimates={fetchEstimates}
                  CSRF={props.CSRF}
                />
              </div>}
    </div>
  );
}
