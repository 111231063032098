import React, {useState} from 'react';
import {deleteLogsAPI} from '../api/Logs';
import {Card, Button, Alert} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';
import Moment from 'moment';
import './LogsCard.css';

export default function LogsCard (props) {
  const [onDelete, setOnDelete] = useState (false);
  const [error, setError] = useState ();

  const showDialog = () => {
    setOnDelete (true);
  };

  const removeLogs = () => {
    deleteLogsAPI (props.CSRF)
      .then (() => {
        setOnDelete (false);
        props.fetchLogs ();
      })
      .catch (error => {
        if (error.type === 'API' && error.content.code === 401) {
          props.handleLogout ();
        } else {
          setError (error.content.message);
        }
      });
  };

  Moment.locale ('de');
  const logs = props.logs.map (function (log) {
    return (
      <LinkContainer key={log.id} to={`/stocks/${log.stock_id}`}>
        <div>
          {Moment (log.date).format ('MM/DD/YYYY hh:mm A') +
            ' - ' +
            log.stock.name +
            ' - ' +
            log.message}
        </div>
      </LinkContainer>
    );
  });

  return (
    <Card>
      <Card.Header>Stock Robot logs</Card.Header>
      <Card.Body>
        <div className="logs-card-textarea">
          {logs}
        </div>
      </Card.Body>
      <Card.Footer className="text-right">
        {error &&
          <Alert className="logs-card-alert" variant="danger">
            {error}
          </Alert>}
        {!onDelete
          ? <Button variant="danger" onClick={showDialog}>
              Remove logs
            </Button>
          : <Button variant="primary" onClick={removeLogs}>
              Are you sure?
            </Button>} {' '}
        {onDelete &&
          <Button variant="secondary" onClick={() => setOnDelete (false)}>
            Cancel
          </Button>}
      </Card.Footer>
    </Card>
  );
}
