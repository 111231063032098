import React, {useEffect} from 'react';
import {Route, Redirect} from 'react-router-dom';

export default function AuthenticatedRoute({component: C, appProps, ...rest}) {
  useEffect (
    () => {
      appProps.setCurrentSite (rest.path);
    },
    [rest]
  );

  return (
    <Route
      {...rest}
      render={props =>
        appProps.isAuthenticated
          ? <C {...props} {...appProps} />
          : <Redirect
              to={`/login?redirect=${props.location.pathname}${props.location.search}`}
            />}
    />
  );
}
