import API from './Config';
import { errorAPI } from '../utils/ErrorHandling';

export const fetchCurrentMarketValuationAPI = () => {
  return new Promise((resolve, reject) => {
    API.get(`stocks/marketvaluations/current`)
      .then(res => {
        var currentMarketValuation = res.data[0];
        resolve(currentMarketValuation);
      })
      .catch(error => {
        reject(errorAPI(error));
      });
  });
};

export const fetchMarketValuationsAPI = timeframe => {
  return new Promise((resolve, reject) => {
    API.get(`stocks/marketvaluations/${timeframe}`)
      .then(res => {
        var marketValuations = res.data;
        resolve(marketValuations);
      })
      .catch(error => {
        reject(errorAPI(error));
      });
  });
};
