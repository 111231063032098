import React, {useState, useEffect} from 'react';
import {Modal, Button} from 'react-bootstrap';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
} from 'recharts';
import './ChartModal.css';

export default function ChartModal (props) {
  const [dataArray, setDataArray] = useState ([]);

  const renderGraph = () => {
    let arrayHistory = props.histories.map (history => {
      if (props.modalKey === 'currency') {
        return {
          year: String (history.year),
          value: parseFloat (
            history.stock.currency.currency_histories[0].rate_in_eur
          ),
        };
      } else {
        return {
          year: String (history.year),
          value: parseFloat (history[props.modalKey]),
        };
      }
    });
    let arrayEstimate = props.estimates.map (estimate => {
      if (props.modalKey === 'currency') {
        let rate_in_eur = 0;
        if (estimate.stock.currency.currency_histories[0]) {
          rate_in_eur =
            estimate.stock.currency.currency_histories[0].rate_in_eur;
        } else {
          rate_in_eur = estimate.stock.currency.rate_in_eur;
        }
        return {year: String (estimate.year), value: parseFloat (rate_in_eur)};
      } else {
        return {
          year: String (estimate.year),
          value: parseFloat (estimate[props.modalKey]),
        };
      }
    });
    const arrayResult = [...arrayHistory, ...arrayEstimate];
    setDataArray (arrayResult);
  };

  useEffect (
    () => {
      if (typeof props.modalKey !== 'undefined') renderGraph ();
    },
    [props.modalKey]
  );

  var titles = {
    price_eur: 'Price',
    eps_eur: 'EPS',
    dividend_eur: 'Dividend',
    payout_ratio: 'Payout ratio',
    profit_growth: 'Profit growth',
    pe_ratio: ' P/E ratio',
    currency: props.stock.currency.currency_symbol + ' in EUR',
  };

  const CustomTooltip = ({active, payload, label}) => {
    if (active) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${label} : ${payload[0].value}`}</p>
          {props.modalKey === 'pe_ratio' &&
            <p className="label">
              Average P/E ratio: {props.stock.calculation.average_pe_ratio}
            </p>}
        </div>
      );
    }

    return null;
  };

  const CustomizedAxisTick = ({x, y, payload}) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          transform="rotate(-35)"
        >
          {payload.value}
        </text>
      </g>
    );
  };

  return (
    <div>
      {typeof props.modalKey !== 'undefined' &&
        <Modal size="lg" show={props.show} onHide={props.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Chart - {titles[props.modalKey]}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <LineChart
              width={720}
              height={400}
              margin={{top: 20, right: 30, left: 20, bottom: 10}}
              data={dataArray}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                interval={0}
                dataKey="year"
                tick={<CustomizedAxisTick />}
              />
              <YAxis />
              <Tooltip content={<CustomTooltip />} />
              {props.modalKey === 'pe_ratio' &&
                <ReferenceLine
                  y={props.stock.calculation.average_pe_ratio}
                  stroke="#3D9970"
                />}
              <Line
                dot={false}
                type="linear"
                dataKey="value"
                animationDuration={0}
                name={titles[props.modalKey]}
              />
            </LineChart>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={props.handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>}
    </div>
  );
}
