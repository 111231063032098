import React, { useEffect, useState } from 'react';
import { CardDeck, Card, Alert, ListGroup } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import {
  fetchCurrentMarketValuationAPI,
  fetchMarketValuationsAPI,
} from '../api/MarketValuation';
import { fetchStocksAPI } from '../api/Stocks';
import { fetchDepotStocksAPI } from '../api/Depot';
import { fetchLogsAPI } from '../api/Logs';
import Moment from 'moment';
import { IoMdArrowRoundUp, IoMdArrowRoundDown } from 'react-icons/io';
import LogsCard from '../components/LogsCard';
import ChartCard from '../components/ChartCard';
import './Dashboard.css';

export default function Dashboard(props) {
  const [marketValuation, setMarketValuation] = useState();
  const [marketValuationOld, setMarketValuationOld] = useState();
  const [marketValuations, setMarketValuations] = useState([]);
  const [isLoadingMarketValuation, setIsLoadingMarketValuation] = useState(
    true
  );
  const [marketValuationTimeframe, setMarketValuationTimeframe] = useState(
    'last7days'
  );
  const [stocks, setStocks] = useState([]);
  const [isLoadingStocks, setIsLoadingStocks] = useState(true);
  const [depot, setDepot] = useState([]);
  const [isLoadingDepot, setIsLoadingDepot] = useState(true);
  const [logs, setLogs] = useState([]);
  const [isLoadingLogs, setIsLoadingLogs] = useState(true);
  const [error, setError] = useState('');

  const fetchCurrentMarketValuation = async () => {
    await fetchCurrentMarketValuationAPI()
      .then(currentMarketValuation => {
        setMarketValuation(currentMarketValuation.value);
        setIsLoadingMarketValuation(false);
      })
      .catch(error => {
        if (error.type === 'API' && error.content.code === 401) {
          props.handleLogout();
        } else {
          setError(error.content.message);
        }
      });
  };

  const fetchMarketValuations = async () => {
    await fetchMarketValuationsAPI(marketValuationTimeframe)
      .then(marketValuations => {
        if (!marketValuationOld) {
          setMarketValuationOld(marketValuations[0].value);
        }
        setMarketValuations(marketValuations);
        setIsLoadingMarketValuation(false);
      })
      .catch(error => {
        setError(error.content.message);
      });
  };

  const fetchStocks = async () => {
    await fetchStocksAPI()
      .then(stocks => {
        setStocks(stocks);
        setIsLoadingStocks(false);
      })
      .catch(error => {
        setError(error.content.message);
      });
  };

  const fetchDepotStocks = async () => {
    await fetchDepotStocksAPI()
      .then(stocks => {
        setDepot(stocks);
        setIsLoadingDepot(false);
      })
      .catch(error => {
        setError(error.content.message);
      });
  };

  const fetchLogs = async () => {
    await fetchLogsAPI(props.match.params.id)
      .then(logs => {
        setLogs(logs);
        setIsLoadingLogs(false);
      })
      .catch(error => {
        setError(error.content.message);
      });
  };

  useEffect(() => {
    fetchCurrentMarketValuation();
    fetchMarketValuations();
    fetchStocks();
    fetchDepotStocks();
    fetchLogs();
  }, []);

  useEffect(
    () => {
      fetchMarketValuations();
    },
    [marketValuationTimeframe]
  );

  Moment.locale('de');
  return (
    <div className="Dashboard">
      {error
        ? <Alert className="text-center" variant="danger">
          {error}
        </Alert>
        : <div>
          <CardDeck>
            {!isLoadingMarketValuation &&
              marketValuation &&
              <Card
                className="text-center"
                bg={
                  parseFloat(marketValuation) < 105
                    ? 'success'
                    : parseFloat(marketValuation) > 125
                      ? 'danger'
                      : 'warning'
                }
                text={'white'}
              >
                <Card.Header>
                  Market valuation
                  </Card.Header>
                <Card.Body className="sm-card">
                  {marketValuation}%{' '}
                  {parseFloat(marketValuation) >
                    parseFloat(marketValuationOld)
                    ? <IoMdArrowRoundUp />
                    : <IoMdArrowRoundDown />}
                  {' '}
                    (
                    {stocks.length}
                  {' '}
                    Stocks)
                  </Card.Body>
              </Card>}
            {!isLoadingDepot &&
              <Card
                className="text-center"
                bg={
                  parseFloat(depot.calc_performance) > 4
                    ? 'success'
                    : parseFloat(depot.calc_performance) < -20
                      ? 'danger'
                      : 'warning'
                }
                text={'white'}
              >
                <Card.Header>
                  Depot performance
                  </Card.Header>
                <Card.Body className="sm-card">
                  {depot.calc_performance}%
                  </Card.Body>
              </Card>}
            {!isLoadingStocks &&
              <Card className="text-center" bg={'secondary'} text={'white'}>
                <Card.Header>Stock Robot last runtime</Card.Header>
                <Card.Body className="sm-card">
                  {Moment(stocks[0].calculation.last_update).format(
                    'hh:mm'
                  )}
                  {' '}
                    -
                    {' '}
                  {Moment(
                    stocks[stocks.length - 1].calculation.last_update
                  ).format('hh:mm A (MM/DD/YYYY)')}
                </Card.Body>
              </Card>}
            {!isLoadingLogs &&
              <Card
                className="text-center"
                bg={parseFloat(logs.length) < 1 ? 'success' : 'danger'}
                text={'white'}
              >
                <Card.Header>Stock Robot logs</Card.Header>
                <Card.Body className="sm-card">
                  {logs.length} entries
                  </Card.Body>
              </Card>}
          </CardDeck>
          <CardDeck>
            {!isLoadingDepot &&
              <Card>
                <Card.Header>Depot</Card.Header>
                <Card.Body style={{ padding: '0' }}>
                  <ListGroup className="text-center" horizontal>
                    <ListGroup.Item
                      style={{
                        width: '18%',
                        borderLeft: 0,
                        borderTop: 0,
                        borderBottom: 0,
                      }}
                    >
                      Value: {depot.value} €
                      </ListGroup.Item>
                    <ListGroup.Item
                      style={{ width: '20%', borderTop: 0, borderBottom: 0 }}
                    >
                      EPS growth: {depot.calc_eps_growth}%
                      </ListGroup.Item>
                    <ListGroup.Item
                      style={{ width: '20%', borderTop: 0, borderBottom: 0 }}
                    >
                      Dividend yield: {depot.calc_dividend_yield}%
                      </ListGroup.Item>
                    <ListGroup.Item
                      style={{ width: '20%', borderTop: 0, borderBottom: 0 }}
                    >
                      Robustness: {depot.calc_robustness}%
                      </ListGroup.Item>
                    <ListGroup.Item
                      style={{
                        width: '22%',
                        borderTop: 0,
                        borderBottom: 0,
                        borderRight: 0,
                      }}
                    >
                      P/E comparison: {depot.calc_pe_comparison}%
                      </ListGroup.Item>
                  </ListGroup>
                </Card.Body>
              </Card>}
          </CardDeck>
          <CardDeck className="newLineOnIpad">
            {!isLoadingMarketValuation &&
              <ChartCard
                timeframe={marketValuationTimeframe}
                setTimeframe={setMarketValuationTimeframe}
                values={marketValuations}
              />}
            {!isLoadingLogs && <LogsCard CSRF={props.CSRF} fetchLogs={fetchLogs} logs={logs} />}
          </CardDeck>
          <CardDeck>
            {!isLoadingStocks &&
              <Card>
                <Card.Header>
                  Stocks with high noise value
                  </Card.Header>
                <ListGroup style={{ fontSize: 13 }} variant="flush">
                  {stocks.map(
                    stock =>
                      stock.calculation.noise > 20 &&
                      <ListGroup.Item key={stock.id}>
                        <LinkContainer
                          key={stock.id}
                          to={`/stocks/${stock.id}`}
                        >
                          <div>
                            {stock.name} with {stock.calculation.noise}%
                            </div>
                        </LinkContainer>
                      </ListGroup.Item>
                  )}
                </ListGroup>
              </Card>}
            {!isLoadingStocks &&
              <Card>
                <Card.Header>
                  Stocks with fiscal year ending
                    {' '}
                  {Moment(Date()).subtract(1, 'M').format('MMMM')}
                </Card.Header>
                <ListGroup style={{ fontSize: 13 }} variant="flush">
                  {stocks
                    .sort(
                      (a, b) => b.calculation.noise - a.calculation.noise
                    )
                    .map(
                      stock =>
                        Moment(stock.fiscal_year, 'M').format('M') ===
                        Moment(Date()).subtract(1, 'M').format('M') &&
                        <ListGroup.Item key={stock.id}>
                          <LinkContainer
                            key={stock.id}
                            to={`/stocks/${stock.id}`}
                          >
                            <div>
                              {stock.name}
                            </div>
                          </LinkContainer>
                        </ListGroup.Item>
                    )}
                </ListGroup>
              </Card>}
            {!isLoadingStocks &&
              <Card>
                <Card.Header>
                  Stocks with fiscal year ending
                    {' '}
                  {Moment(Date()).format('MMMM')}
                </Card.Header>
                <ListGroup style={{ fontSize: 13 }} variant="flush">
                  {stocks.map(
                    stock =>
                      Moment(stock.fiscal_year, 'M').format('M') ===
                      Moment(Date()).format('M') &&
                      <ListGroup.Item key={stock.id}>
                        <LinkContainer
                          key={stock.id}
                          to={`/stocks/${stock.id}`}
                        >
                          <div>
                            {stock.name}
                          </div>
                        </LinkContainer>
                      </ListGroup.Item>
                  )}
                </ListGroup>
              </Card>}
          </CardDeck>
        </div>}
    </div>
  );
}
