import React, {useState, useEffect} from 'react';
import {Alert, Spinner} from 'react-bootstrap';
import {fetchDepotStocksAPI} from '../api/Depot';
import {fetchStocksAPI} from '../api/Stocks';
import StockTable from '../components/StockTable';
import DepotModal from '../components/DepotModal';
import './Depot.css';

export default function Depot (props) {
  const [depot, setDepot] = useState ([]);
  const [isLoadingDepot, setIsLoadingDepot] = useState (true);
  const [stocks, setStocks] = useState ([]);
  const [isLoadingStocks, setIsLoadingStocks] = useState (true);
  const [sort, setSort] = useState ([]);
  const [error, setError] = useState ('');
  const [modalDepotAmount, setModalDepotAmount] = useState ('');

  const handleClose = () => props.setModalDepotVisible (false);

  const fetchDepotStocks = async () => {
    await fetchDepotStocksAPI ()
      .then (stocks => {
        setDepot (stocks);
        setIsLoadingDepot (false);
      })
      .catch (error => {
        if (error.type === 'API' && error.content.code === 401) {
          props.handleLogout ();
        } else {
          setError (error.content.message);
          setIsLoadingDepot (false);
        }
      });
  };

  const fetchStocks = async () => {
    await fetchStocksAPI ()
      .then (stocks => {
        setStocks (stocks);
        setIsLoadingStocks (false);
      })
      .catch (error => {
        if (error.type === 'API' && error.content.code === 401) {
          props.handleLogout ();
        } else {
          setError (error.content.message);
          setIsLoadingStocks (false);
        }
      });
  };

  useEffect (() => {
    fetchDepotStocks ();
    fetchStocks ();
  }, []);

  function getStockArray (depot, searchString) {
    let depotStocks = depot.depotStocks;
    if (searchString) {
      return depotStocks.filter (depotStock => {
        return depotStock.stock.name
          .toLowerCase ()
          .match (searchString.toLowerCase ());
      });
    } else {
      return depotStocks;
    }
  }

  const sortStocks = (column, paths = 0) => {
    const tempDepot = depot;
    const temp = [...depot.depotStocks];
    if (column === 'name') {
      if (sort[0] === column && sort[1] === 'ASC') {
        setSort ([column, 'DESC']);
        tempDepot.depotStocks = temp.sort ((a, b) =>
          b.stock.name.localeCompare (a.stock.name)
        );
        setDepot (tempDepot);
      } else {
        setSort ([column, 'ASC']);
        tempDepot.depotStocks = temp.sort ((a, b) =>
          a.stock.name.localeCompare (b.stock.name)
        );
        setDepot (tempDepot);
      }
    } else {
      if (sort[0] === column && sort[1] === 'ASC') {
        setSort ([column, 'DESC']);
        if (paths === 1)
          tempDepot.depotStocks = temp.sort ((a, b) => b[column] - a[column]);
        else
          tempDepot.depotStocks = temp.sort (
            (a, b) => b.stock.calculation[column] - a.stock.calculation[column]
          );
        setDepot (tempDepot);
      } else {
        setSort ([column, 'ASC']);
        if (paths === 1)
          tempDepot.depotStocks = temp.sort ((a, b) => a[column] - b[column]);
        else
          tempDepot.depotStocks = temp.sort (
            (a, b) => a.stock.calculation[column] - b.stock.calculation[column]
          );
        setDepot (tempDepot);
      }
    }
  };

  return (
    <div className="Depot">
      {isLoadingDepot
        ? <div className="text-center padding">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        : error
            ? <div className="padding">
                <Alert className="text-center" variant="danger">
                  {error}
                </Alert>
              </div>
            : <StockTable
                stocks={getStockArray (depot, props.search)}
                columns={props.columns}
                sortStocks={sortStocks}
                setCurrentSite={props.setCurrentSite}
                setModalDepotVisible={props.setModalDepotVisible}
                setModalDepotID={props.setModalDepotID}
                setModalDepotAmount={setModalDepotAmount}
              />}
      {!isLoadingStocks &&
        <DepotModal
          show={props.modalDepotVisible}
          handleClose={handleClose}
          ID={props.modalDepotID}
          amount={modalDepotAmount}
          stocks={stocks}
          fetchDepotStocks={fetchDepotStocks}
          CSRF={props.CSRF}
        />}
    </div>
  );
}
